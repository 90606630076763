import React from 'react'

import type { PageProps } from 'gatsby'
import { isObject } from '@wicadu/arepa/utils'

import { TemplateProvider } from '@hooks/useTemplate'
import HeadSEO from '@atoms/HeadSEO'
import { usePageContext } from '@HOCs/Providers/PageContext'
import PrivacyPolicyTemplate from '@templates/Legal/PrivacyPolicy'
import Consts from '@utils/constants'
import { type IPageProps } from '@ts-types/Page'
import { PAGES_ID } from '@config/pages/const'
import { Translations } from '@ts-types/Translations'
import { SEO_TRANSLATE } from '@utils/constants/translates'
import { getUrlByPageId } from '@utils/getUrlByPageId'

interface Props extends IPageProps {}

const { LEGAL_PRIVACY_POLICY_TRANSLATION } = Consts.translates

const PrivacyPolicy: React.FC<PageProps> = () => {
  const { lang } = usePageContext()

  const sections = Object.values(LEGAL_PRIVACY_POLICY_TRANSLATION[lang].ARTICLE)
    .filter((t): t is Translations => isObject(t))
    .map(({ TITLE, CONTENT }: any) => ({
      title: TITLE,
      content: CONTENT,
    }))

  return (
    <TemplateProvider
      value={{ translate: LEGAL_PRIVACY_POLICY_TRANSLATION, lang, sections }}
    >
      <PrivacyPolicyTemplate />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}

  const { organization } = Consts.schemeOrg

  const pageTitle: string = `${
    LEGAL_PRIVACY_POLICY_TRANSLATION[lang].SEO_TITLE
  } | ${SEO_TRANSLATE[lang].TITLE_SUFIX || ''}`

  const faqList = Object.values(LEGAL_PRIVACY_POLICY_TRANSLATION[lang].ARTICLE)
    .filter((t): t is Translations => isObject(t))
    .map(({ SEO_TITLE, CONTENT }: any) => ({
      '@type': 'Question',
      name: SEO_TITLE,
      headline: SEO_TITLE,
      answerCount: 1,
      acceptedAnswer: {
        '@type': 'Answer',
        text: Array.isArray(CONTENT) ? CONTENT.join(' ') : CONTENT,
        datePublished: '2025-03-18T00:00:00Z',
        dateModified: '2025-03-18T00:00:00Z',
        url: getUrlByPageId(PAGES_ID.LEGAL_PRIVACY_POLICY, lang),
      },
    }))

  return (
    <>
      <HeadSEO
        title={LEGAL_PRIVACY_POLICY_TRANSLATION[lang].SEO_TITLE as string}
        description={
          LEGAL_PRIVACY_POLICY_TRANSLATION[lang].SEO_DESCRIPTION as string
        }
        keywords={LEGAL_PRIVACY_POLICY_TRANSLATION[lang].SEO_KEYWORDS as string}
        lang={lang}
        pageId={PAGES_ID.LEGAL_PRIVACY_POLICY}
        jsonLd={[
          {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: pageTitle,
            description: LEGAL_PRIVACY_POLICY_TRANSLATION[lang].SEO_DESCRIPTION,
            url: getUrlByPageId(PAGES_ID.LEGAL_PRIVACY_POLICY, lang),
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: LEGAL_PRIVACY_POLICY_TRANSLATION[lang].BREADCRUMBS[
                    'HOME'
                  ],
                  item: getUrlByPageId(PAGES_ID.HOME, lang),
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: LEGAL_PRIVACY_POLICY_TRANSLATION[lang].BREADCRUMBS[
                    'LEGAL'
                  ],
                  item: getUrlByPageId(PAGES_ID.LEGAL_HOME, lang),
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: LEGAL_PRIVACY_POLICY_TRANSLATION[lang].BREADCRUMBS[
                    'PRIVACY_POLICY'
                  ],
                  item: getUrlByPageId(PAGES_ID.LEGAL_PRIVACY_POLICY, lang),
                },
              ],
            },
            datePublished: '2024-07-09T00:00:00Z',
            dateModified: '2025-03-18T00:00:00Z',
            publisher: organization.organizationField,
            author: organization.organizationField,
            mainEntity: {
              '@type': 'FAQPage',
              name: pageTitle,
              mainEntity: faqList,
              datePublished: '2024-07-09T00:00:00Z',
              dateModified: '2025-03-18T00:00:00Z',
              keywords: LEGAL_PRIVACY_POLICY_TRANSLATION[lang].SEO_KEYWORDS,
            },
          },
        ]}
      />
    </>
  )
}

export default PrivacyPolicy
